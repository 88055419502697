export const AppointmentStatus = {
  completed: 'COMPLETED',
  confirm: 'CONFIRM',
  pending: 'PENDING',
  canceled: 'CANCELED',
  'in-progress': 'IN_PROGRESS',
  'processing-payment': 'PROCESSING_PAYMENT',
  'payment-declined': 'PAYMENT_DECLINED',
  'no-attend': 'NO_ATTEND',
  'no-attend-user': 'NO_ATTEND_USER',
  'no-attend-specialist': 'NO_ATTEND_SPECIALIST',
  'in-waiting-room': 'IN_WAITING_ROOM',
  'in-progress-waiting': 'IN_PROGRESS_WAITING',
  'non-completed': 'NON_COMPLETED',
  'no-attend-user-reschedule': 'NO_ATTEND_USER_WITH_RESCHEDULE'
}

export const ContentLength = 340

export const StatusColor = {
  COMPLETED: 'purple',
  CONFIRM: 'purple',
  PENDING: 'black',
  IN_PROGRESS: 'purple',
  CANCELED: 'red',
  NO_ATTEND: 'red',
  NO_ATTEND_USER: 'red',
  NO_ATTEND_SPECIALIST: 'red',
  IN_WAITING_ROOM: 'purple',
  IN_PROGRESS_WAITING: 'purple',
  NON_COMPLETED: 'red'
}

export const StatusToAttendAppointment = [
  'CONFIRM', 'IN_PROGRESS', 'IN_WAITING_ROOM', 'IN_PROGRESS_WAITING'
]

export const StatusToCompleteNonVirtualAppointment = [
  'CONFIRM', 'IN_PROGRESS'
]

export const AppointmentType = {
  'first-contact': 'FIRST_CONTACT',
  orientation: 'ORIENTATION',
  other: 'OTHER',
  psychology: 'PSYCHOLOGY',
  psychiatry: 'PSYCHIATRY',
  nutrition: 'NUTRITION'
}
