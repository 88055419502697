<template>
  <div class="space-y-2">
    <div class="flex margin-bottom">
      <div class="heading-tag flex-grow self-center">
        {{ title }}
      </div>

      <!-- <div
        @click="handleEditToggle"
        class="body-big text-purple cursor-pointer flex-none self-center"
      >
        {{ edit ? $translations.general.save : $translations.general.edit }}
      </div> -->
    </div>

    <TextareaField
      v-if="edit"
      class="margin-top-zero"
      :placeholder="title"
      :value="value"
      @changed="setValue"
    />

    <div
      class="body-small bg-gray-5 rounded-md border border-purple-20 padding-all-xs"
      v-else
    >
      <div :class="{ 'line-clamp-6': showClamp }">
        {{ content || '---' }}
      </div>

      <div
        @click="showClamp = !showClamp"
        class="cursor-pointer text-purple"
        v-if="content.length > length"
      >
        {{ readMoreText }}
      </div>
    </div>
  </div>
</template>
<script>
import { TextareaField } from '@seliaco/red-panda'
import { ContentLength } from '@/constants/appointment'

export default {
  name: 'PreviewContentBox',
  components: { TextareaField },
  data () {
    return {
      showClamp: true,
      length: ContentLength,
      value: this.content
    }
  },
  mounted () {
    this.value = this.content
  },
  methods: {
    handleEditToggle () {
      this.$emit('changed', this.value)
    },
    setValue (value) {
      this.value = value
    }
  },
  computed: {
    readMoreText () {
      return this.showClamp
        ? this.$translations.general['read-more']
        : this.$translations.general['read-less']
    }
  },
  props: {
    content: String,
    title: String,
    edit: Boolean
  },
  watch: {
    content: {
      handler (value) {
        this.value = value
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
