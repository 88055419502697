<template>
  <div>
    <div class="buttons">
      <Button
        v-if="getPrincipal.visible"
        :text="action.text"
        :disable="getPrincipal.disabled"
        :icon="action.icon"
        @clicked="action.callback()"
      />
      <Button
        class="fit-content"
        v-if="showForward"
        icon="icon-bolt-off"
        @clicked="showForwardDialog = true"
      />
    </div>
    <div
      class="buttons"
      v-if="getPrincipal.reschedule.visible || getPrincipal.cancel.visible"
    >
      <Button
        v-if="getPrincipal.reschedule.visible"
        :text="$translations.buttons.reschedule"
        color="gray"
        type="outline"
        icon="icon-timer-calendar-off"
        :disable="getPrincipal.reschedule.disabled"
        @clicked="handleActionEvent('reschedule')" />
      <Button
        v-if="getPrincipal.cancel.visible"
        :text="$translations.buttons.cancel"
        color="red"
        type="outline"
        :disable="getPrincipal.cancel.disabled"
        @clicked="handleActionEvent('cancel')" />
    </div>

    <!-- conclude modal -->
    <CenteredDialog
      v-if="showConcludeModal"
      :title="$translations.appointment.action['conclude']"
      :options="concludeOptions"
      icon="icon-appointment-on"
      @close="showConcludeModal = false"
    />

    <!-- forward dialog -->
    <ForwardAppointmentDialog
      v-if="showForwardDialog"
      @close="showForwardDialog = false"
      :appointment="appointment"
    />
  </div>
</template>

<script>
import { AppointmentStatus, AppointmentType } from '@/constants/appointment'
import { Appointments, Button, CenteredDialog } from '@seliaco/red-panda'
import ForwardAppointmentDialog from '@/views/agenda/components/v2/ForwardAppointmentDialog.vue'

export default {
  name: 'AppointmentStatusButton',
  components: {
    ForwardAppointmentDialog,
    Button,
    CenteredDialog
  },
  computed: {
    getPrincipal () {
      let disabled = true
      let visible = true
      let cancel = { disabled: true, visible: false }
      let reschedule = { disabled: true, visible: false }

      switch (this.appointment.status) {
        case this.appointmentStatus.completed:
        case this.appointmentStatus['non-completed']:
        case this.appointmentStatus['no-attend-user']:
        case this.appointmentStatus['no-attend-specialist']:
          disabled = false
          break

        case this.appointmentStatus.pending:
        case this.appointmentStatus['payment-declined']:
          visible = false
          cancel = reschedule = { disabled: false, visible: true }
          break

        case this.appointmentStatus['processing-payment']:
          visible = false
          reschedule = { disabled: false, visible: true }
          break

        case this.appointmentStatus['in-progress']:
          reschedule = cancel = { disabled: true, visible: true }
          if (this.appointment.virtual) {
            disabled = false
          }
          if (!this.appointment.virtual) {
            visible = false
          }
          break

        case this.appointmentStatus['in-waiting-room']:
        case this.appointmentStatus['in-progress-waiting']:
          reschedule = cancel = { disabled: true, visible: true }
          disabled = false
          break

        case this.appointmentStatus.confirm:
          reschedule = { disabled: false, visible: true }
          cancel = { disabled: this.appointment.service.type !== AppointmentType.orientation, visible: true }
          if (this.aboutToStart() && this.appointment.virtual) {
            disabled = false
          }
          if (!this.appointment.virtual) {
            visible = false
          }
          break

        case this.appointmentStatus['no-attend']:
          visible = false
          reschedule = { disabled: false, visible: true }
          break

        case this.appointmentStatus.canceled:
          visible = true
          disabled = false
          reschedule = { disabled: false, visible: false }
          cancel = { disabled: false, visible: false }
          break

        case this.appointmentStatus['no-attend-user-reschedule']:
          visible = true
          disabled = false
          cancel = reschedule = { disabled: false, visible: false }
          break
      }
      return { disabled, visible, cancel, reschedule }
    },
    diffToday () {
      return this.$moment(this.appointment.starts_at).diff(this.$moment(), 'days')
    },
    diffTodayText () {
      const diff = this.diffToday
      const text = diff > 1
        ? this.$translations.buttons['diff-time'].days
        : this.$translations.buttons['diff-time'].day
      return `${diff} ${text}`
    },
    action () {
      switch (this.appointment.status) {
        case 'COMPLETED':
        case 'NO_ATTEND_USER':
          return {
            text: this.$translations.buttons['new-request'],
            callback: () => {
              this.$router.push({
                name: 'AppointmentCreate',
                params: {
                  id: this.appointment.specialist.id
                },
                query: {
                  patient_id: this.appointment.patient.id,
                  origin_appointment_id: this.appointment.id
                }
              })
            }
          }

        case 'PENDING':
        case 'PAYMENT_DECLINED':
          return null

        case 'CONFIRM':
          /*          if (this.diffToday >= 0 && !this.aboutToStart()) {
            return {
              text: `${this.$translations.buttons['diff-time'].base} ${this.diffTodayText}`
            }
          } else if (this.aboutToStart() && this.appointment.virtual) {
            return {
              text: this.$translations.appointment.attend,
              callback: () => {
                this.$router.push({
                  name: 'Lobby',
                  params: {
                    id: this.appointment.id
                  }
                })
              }
            }
          } else {
            return null
          } */
          return {
            text: this.$translations.appointment.attend,
            callback: () => {
              this.$router.push({
                name: 'Lobby',
                params: {
                  id: this.appointment.id
                },
                query: {
                  service_id: this.appointment.service.id
                }
              })
            }
          }

        case 'IN_WAITING_ROOM':
        case 'IN_PROGRESS_WAITING':
          if (this.appointment.virtual) {
            return {
              text: this.$translations.appointment.attend,
              callback: () => {
                this.$router.push({
                  name: 'Lobby',
                  params: {
                    id: this.appointment.id
                  },
                  query: {
                    service_id: this.appointment.service.id
                  }
                })
              }
            }
          } else {
            return null
          }

        case 'IN_PROGRESS':
          if (this.appointment.virtual) {
            return {
              text: this.$translations.appointment.attend,
              callback: () => {
                this.$router.push({
                  name: 'Lobby',
                  params: {
                    id: this.appointment.id
                  },
                  query: {
                    service_id: this.appointment.service.id
                  }
                })
              }
            }
          } else {
            return {
              text: this.$translations.appointment.action.conclude,
              callback: () => {
                this.onConcludeAppointment()
              }
            }
          }

        case 'CANCELED': {
          return {
            text: this.$translations.appointment.action.schedule,
            callback: () => {
              this.$router.push({ name: 'AppointmentCreate', query: { patient_id: this.appointment.patient.id } })
            }
          }
        }

        case 'NO_ATTEND_USER_WITH_RESCHEDULE':
          return {
            text: this.$translations.buttons['reschedule-alt'],
            callback: () => {
              this.handleActionEvent('reschedule')
            },
            icon: 'icon-timer-calendar-off'
          }

        case 'NON_COMPLETED':
        case 'NO_ATTEND_SPECIALIST':
          return {
            text: this.$translations.buttons.reschedule,
            callback: () => {
              this.handleActionEvent('reschedule')
            },
            icon: 'icon-timer-calendar-off'
          }

        default:
          return null
      }
    },
    showForward () {
      const valid = [
        this.appointmentStatus.confirm,
        this.appointmentStatus['non-completed'],
        this.appointmentStatus['no-attend-user'],
        this.appointmentStatus['no-attend-user-with-reschedule'],
        this.appointmentStatus['no-attend-specialist']
      ]
      return valid.includes(this.appointment.status)
    }
  },
  data () {
    return {
      appointmentStatus: AppointmentStatus,
      showConcludeModal: false,
      concludeOptions: [
        {
          text: this.$translations.appointment.action.concluded,
          style: 'FILLED',
          action: () => {
            this.changeAppointmentStatus('COMPLETED')
            this.showConcludeModal = false
            this.navigateAppointmentDetail()
          }
        },
        {
          text: this.$translations.appointment.action['no-attended'],
          style: 'FILLED',
          severity: 'DANGER',
          action: () => {
            this.changeAppointmentStatus('NO_ATTEND_USER')
            this.showConcludeModal = false
            this.navigateAppointmentDetail()
          }
        },
        {
          text: this.$translations.general.cancel,
          style: 'OUTLINED',
          action: () => {
            this.showConcludeModal = false
          }
        }
      ],
      showForwardDialog: false
    }
  },
  methods: {
    aboutToStart () {
      const today = this.$moment()
      const before = this.$moment(this.appointment.starts_at).subtract(15, 'minutes')
      const after = this.$moment(this.appointment.starts_at).add(15, 'minutes')

      return this.$moment(today).isBetween(before, after)
    },
    onConcludeAppointment () {
      this.showConcludeModal = true
    },
    handleActionEvent (actionName) {
      switch (actionName) {
        case 'reschedule':
          this.$globalEvent.$emit('modal/reschedule', {
            showDialog: { reschedule: { open: true, close: false } },
            appointment: this.appointment
          })
          break

        case 'cancel':
          this.$globalEvent.$emit('modal/cancel', {
            showDialog: { cancel: { open: true, close: false } },
            appointment: this.appointment
          })
          break
      }
    },
    changeAppointmentStatus (status) {
      Appointments.update(this.appointment.id, {
        status
      })
        .then(() => {
          this.$toast({
            text: this.$translations.success.default,
            severity: 'success'
          })

          this.$emit('changed')
        })
        .catch(() => {
          this.$toast({
            text: this.$translations.error.default,
            severity: 'error'
          })
        })
    },
    navigateAppointmentDetail () {
      this.$router.push({ name: 'AppointmentDetail', params: { id: this.appointment.id } })
    }
  },
  props: {
    appointment: Object
  }
}
</script>

<style lang="sass" scoped>
.buttons
  margin-top: 16px
  display: flex
  gap: 8px
  .fit-content
    width: fit-content
</style>
