<template>
  <DialogContainer
    class="forward-dialog"
    @action="handleAction"
    :title="$translations['forward-appointment'].dialog.title"
    :confirmation="$translations['forward-appointment'].dialog.description"
    :buttons="buttons"
    :lang="$store.getters.language.lang"
    icon="icon-bolt-on text-purple"
    content-icon-background="bg-purple-10"
    btn-close-color="gray"
    verticalArrangement
  >
    <template v-slot:content>
      <div class="margin-top-lg margin-bottom">
        <Checkbox
          :text="$translations['forward-appointment'].dialog.confirm"
          type="fullcheck-gray"
          :checked="checked"
          @value="checked = !checked"
        />
      </div>
      <TextareaField
        :config="inputConfig"
        :label="$translations['forward-appointment'].dialog.placeholder"
        @change="setReason"
      />
    </template>
  </DialogContainer>
</template>

<script>
import { DialogContainer, Checkbox, SpeAppointments } from '@seliaco/red-panda'
import { TextareaField } from '@seliaco/sea-otter'

export default {
  components: {
    DialogContainer,
    TextareaField,
    Checkbox
  },
  props: {
    appointment: Object
  },
  data () {
    return {
      reason: null,
      checked: false,
      loading: false
    }
  },
  methods: {
    handleAction (key) {
      switch (key) {
        case 'init':
          this.loading = true
          SpeAppointments.forwardAppointment(this.appointment.id, { reason: this.reason })
            .catch((e) => {
              this.$toast({
                severity: 'error',
                text: e.message
              })
            }).finally(() => {
              this.loading = false
              this.$router.push({
                name: 'Lobby',
                params: {
                  id: this.appointment.id
                },
                query: {
                  service_id: this.appointment.service.id,
                  bypassLobby: true
                }
              })
            })
          break
        case 'close':
          this.$emit('close')
          break
      }
    },
    setReason (reason) {
      this.reason = reason
    }
  },
  computed: {
    buttons () {
      return [
        {
          key: 'init',
          text: this.$translations['forward-appointment'].dialog.buttons.init,
          isInvalid: !(this.reason && this.checked),
          loading: this.loading
        }
      ]
    },
    inputConfig () {
      return {
        label: this.$translations['forward-appointment'].dialog.placeholder,
        error: null,
        readonly: false,
        disabled: false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.forward-dialog
  ::v-deep .confirmation
    margin-top: 0 !important
  ::v-deep .heading
    margin: 16px 0 !important
</style>
