<template>
  <PageContainer
    :title="$translations.appointment.detail.title"
    :back="actions.back"
  >
    <template v-slot:content>
      <div class="page-segment">
        <div class="page-segment-content">
          <!-- loader -->
          <div class="skeleton-container" v-if="loading">
            <Skeleton
              class="margin-bottom"
              width="120px"
              height="21px"
              border-radius="16px"
            />
            <Skeleton
              class="margin-bottom-lg"
              width="100%"
              height="96px"
              borderRadius="16px"
            />
            <Skeleton
              class="margin-bottom-xs"
              width="120px"
              height="21px"
              border-radius="16px"
            />
            <Skeleton
              class="margin-y"
              width="220px"
              height="48px"
              border-radius="16px"
            />

            <div class="skeleton-container-grid">
              <Skeleton
                class="skeleton-container-grid-item"
                v-for="index in 4"
                :key="index"
              />
            </div>
            <div class="skeleton-container-buttons">
              <Skeleton
                class="skeleton-container-buttons-item"
                v-for="index in 2"
                :key="index"
              />
            </div>
          </div>

          <div v-if="appointment && !loading">
            <!-- patient card -->
            <div
              class="heading-small margin-bottom"
              v-text="$translations.patients.singular"
            />
            <PersonCard
              class="margin-bottom-lg"
              v-selectable-container="{
                callback: () =>
                  $router.push({
                    name: 'PatientDetail',
                    params: { id: appointment.patient.id },
                    query: { back: $route.fullPath }
                  })
              }"
              :image="appointment.patient.profile_picture"
              :title="`${appointment.patient.first_name} ${appointment.patient.last_name}`"
              :first-line="cardFirstLine"
              :middle-line="cardMiddleLine"
              :showAction="true"
            />

            <!-- content -->
            <div
              class="heading-small margin-bottom-xs"
              v-text="$translations.appointment.detail.subtitle"
            />
            <div class="service padding-y">
              <!-- icon -->
              <div
                class="service-icon heading padding-all"
                :class="icon.iconName"
                v-bind:style="{
                  background: icon.background,
                  color: icon.color
                }"
              />

              <!-- name and status -->
              <div class="service-name">
                <div
                  class="heading-small service-name-title"
                  v-text="appointment.service.service_type.name"
                />
                <div
                  class="content-title service-name-subtitle"
                  v-text="$translations.status[appointment.status]"
                  :class="{
                    'service-name-subtitle-danger': redStatus(
                      appointment.status
                    )
                  }"
                />
              </div>
            </div>
            <!-- appointment detail info: date and duration -->
            <div class="service-info">
              <!-- date -->
              <StatItem
                v-for="(item, index) in serviceList"
                :key="index"
                :icon="item.icon"
                :text="item.text"
                :title="item.title"
                :sub-title="item.subtitle"
                :extra-data="item.extraData"
              />
            </div>

            <AppointmentStatusButton
              class="margin-top-xl"
              v-if="appointment"
              :appointment="appointment"
            />

            <InfoBanner
              v-if="showBanner"
              icon="icon-warning-circle-off"
              icon-color="yellow"
              :text="$translations.appointment.detail['can-reschedule']"
            />

            <!-- hints -->
            <div class="hint">
              <!-- call supporting text -->
              <template v-if="showCallSupport">
                <span
                  class="small"
                  v-html="$translations.appointment.detail['call-support'][0]"
                />
                <span
                  @click="openSupport"
                  class="link small text-purple cursor-pointer"
                  v-html="$translations.appointment.detail['call-support'][1]"
                />
              </template>

              <!-- terms and conditions -->
              <template v-if="showTermsAndConditions">
                <span
                  class="small"
                  v-html="$translations.appointment.detail.terms[0]"
                />
                <span
                  @click="openTermsAndConditions"
                  class="link small text-purple cursor-pointer"
                  v-html="$translations.appointment.detail.terms[1]"
                />
                <span
                  class="small"
                  v-html="$translations.appointment.detail.terms[2]"
                />
                <span
                  @click="openTermsAndConditions"
                  class="link small text-purple cursor-pointer"
                  v-html="$translations.appointment.detail.terms[3]"
                />
              </template>

              <!-- reschedule terms -->
              <template v-if="showRescheduleTerms">
                <span
                  class="small"
                  v-html="
                    $translations.appointment.detail['reschedule-terms'][0]
                  "
                />
              </template>
            </div>
          </div>

          <!-- reason -->
          <template
            v-if="
              appointment && appointment.reason && appointment.reason.length > 0
            "
          >
            <PreviewContentBox
              class="margin-top"
              v-if="appointment.reason.length"
              :content="appointment.reason"
              :title="$translations.appointment.detail.reason"
            />
          </template>
        </div>
      </div>

      <!-- notes -->
      <div
        v-if="clinicalHistory && notes.content && !loading"
        class="page-segment"
      >
        <div class="page-segment-content">
          <PreviewContentBox
            @changed="toggleEditNotes"
            :edit="editNotes"
            :content="notes.content"
            :title="$translations.appointment.detail.notes"
          />
        </div>
      </div>

      <!-- tasks -->
      <div
        v-if="clinicalHistory && tasks.content && !loading"
        class="page-segment"
      >
        <div class="page-segment-content">
          <PreviewContentBox
            @changed="toggleEditTasks"
            :edit="editTasks"
            :content="tasks.content"
            :title="$translations.appointment.detail.task"
          />
        </div>
      </div>

      <MedicalHistory />

      <AppointmentInstrumentSection
        origin="specialist"
        :lang="$store.getters.language.lang"
        :route="$route"
        :router="$router"
        :appointment-id="$route.params.id"
      />

      <!-- informed consent-->
      <div class="page-segment" v-if="showInformedConsentButton || !isComplete">
        <div class="page-segment-content">
          <div
            class="body informed-consent-text"
            v-text="
              $translations.appointment.detail['informed-consent'].description
            "
          />
          <Button
            type="link"
            :text="
              $translations.appointment.detail['informed-consent'].buttons[0]
            "
            @clicked="goPatientForm"
            v-if="!isComplete"
          />
          <Button
            type="link"
            :text="
              $translations.appointment.detail['informed-consent'].buttons[1]
            "
            @clicked="sendInformedConsent"
            :loading="buttonLoading"
            v-if="showInformedConsentButton"
          />
        </div>
      </div>
      <!-- skeleton -->
      <div class="page-segment" v-if="sectionLoading">
        <div class="page-segment-content">
          <div class="skeleton-informed-consent">
            <Skeleton class="skeleton-informed-consent-item" />
            <Skeleton
              class="skeleton-informed-consent-button"
              v-for="index in 2"
              :key="index"
            />
          </div>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  ServiceIcons,
  Appointments,
  SpecialistsPatientsNotes,
  SpeContracts,
  PageContainer,
  StatItem,
  CurrencyFilter,
  priceAppointment,
  labelAppointment,
  AftercallSurveyMixin,
  Skeleton,
  PersonCard,
  InfoBanner,
  Button,
  selectableContainer,
  AppointmentInstrumentSection
} from '@seliaco/red-panda'
import AppointmentStatusButton from '@/components/sections/AppointmentStatusButton'
import PreviewContentBox from '@/components/PreviewContentBox'
import { mapGetters } from 'vuex'
import { Browser } from '@capacitor/browser'
import AppointmentDetailMixin from '@/mixins/appointment-detail-mixin'
import MedicalHistory from '@/views/medical-history/MedicalHistory'
import { PatientDetail } from '@/types/events'

export default {
  name: 'AppointmentDetail',
  mixins: [AftercallSurveyMixin, AppointmentDetailMixin],
  directives: { selectableContainer },
  components: {
    PageContainer,
    Skeleton,
    AppointmentStatusButton,
    PreviewContentBox,
    StatItem,
    PersonCard,
    InfoBanner,
    MedicalHistory,
    Button,
    AppointmentInstrumentSection
  },
  data () {
    return {
      loading: true,
      appointment: null,
      date: null,
      price: null,
      label: null,
      day: null,
      informedConsent: null,
      buttonLoading: false,
      sectionLoading: false,
      notes: {
        type: 'NOTE',
        content: ''
      },
      tasks: {
        type: 'TASK',
        content: ''
      },
      editTasks: false,
      editNotes: false,
      actions: {
        back: {
          callback: () => {
            if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.push({
                name: 'Home'
              })
            }
          }
        }
      },
      serviceList: [],
      origin: 'SPECIALIST',
      surveyId: null
    }
  },
  created () {
    this.getAppointment()
    this.getNotes()
    this.$globalEvent.$on('refresh/appointment', () => {
      this.getAppointment()
    })
  },
  methods: {
    getAppointment () {
      this.loading = true
      Appointments.getAppointmentDetail(this.$route.params.id)
        .then((response) => {
          this.appointment = response
          this.appointmentId = this.appointment.id
          this.$store.commit('medical/SET_APPOINTMENT', {
            appointment: this.appointment
          })

          if (this.$route.query.origin === 'aftercall') {
            this.getSurveyConfig(this.origin, this.appointmentId)
          }

          this.price = priceAppointment(
            this.appointment.price || this.appointment.service.price || 0
          )
          this.label = labelAppointment(
            this.appointment?.user_package_id,
            this.appointment.transactions,
            this.$translations
          )
          this.date = this.dateAppointment(this.appointment.starts_at)
        })
        .finally(() => {
          this.loading = false
          this.setServiceList()
          this.retrieveInformedConsent()
        })
    },
    dateAppointment (date) {
      date = this.$moment(date).tz(this.$store.getters.userTimezone)
      return {
        day: date.format('ddd'),
        fullDate: date.format('D MMM, YYYY'),
        time: date.format('hh:mm a')
      }
    },
    getNotes () {
      SpecialistsPatientsNotes.read({
        columns: `
          content,
          type,
          id
        `,
        eq: {
          appointment: this.$route.params.id
        }
      }).then((response) => {
        response.data.forEach((o) => {
          if (o.type === 'TASK') {
            this.tasks = o
          } else {
            this.notes = o
          }
        })
      })
    },
    async setNotes (note) {
      let service = 'create'
      let query = null

      if (note && note.id) {
        service = 'update'
        query = {
          id: note.id
        }
      }

      const form = {
        appointment: this.appointmentId,
        specialist: this.appointment.specialist.id,
        patient: this.appointment.patient.id,
        type: note.type,
        content: note.content
      }

      SpecialistsPatientsNotes[service](form, query)
        .then((notes) => {
          const id = notes[0].id

          if (note.type === 'NOTE') {
            this.editNotes = false
            this.notes = {
              ...this.notes,
              id
            }
          } else {
            this.editTasks = false
            this.tasks = {
              ...this.tasks,
              id
            }
          }

          this.$toast({
            severity: 'success',
            text: this.$translations.success.updated
          })
        })
        .catch(() => {
          this.$toast({
            severity: 'error',
            text: this.$translations.error.default
          })
        })
    },
    toggleEditTasks (value) {
      this.tasks.content = value

      if (this.editTasks) {
        this.setNotes(this.tasks)
      } else {
        this.editTasks = true
      }
    },
    toggleEditNotes (value) {
      this.notes.content = value

      if (this.editNotes) {
        this.setNotes(this.notes)
      } else {
        this.editNotes = true
      }
    },
    patientAge () {
      const birthday = this.$moment(this.appointment.patient?.birthday).locale(
        this.user.language
      )
      const now = this.$moment(new Date()).locale(this.user.language)
      return now.diff(birthday, 'years')
    },
    setServiceList () {
      this.serviceList = [
        /* date */
        {
          icon: null,
          text: this.date.day,
          title: this.date.fullDate,
          subtitle: this.date.time,
          extraData: null
        },
        /* duration */
        {
          icon: 'icon-timer-off',
          text: null,
          title: this.$translations.form.duration,
          subtitle: `${this.appointment.service.service_type.duration} min`,
          extraData: null
        },
        /* modality */
        {
          icon: 'icon-video-outline-off',
          text: null,
          title: this.$translations.form['service-modality'],
          subtitle: this.virtual,
          extraData: null
        }
      ]
    },
    async openTermsAndConditions () {
      await Browser.open({ url: process.env.VUE_APP_TERMS_AND_CONDITIONS_URL })
    },
    async openSupport () {
      let url = `https://wa.me/send?text=${this.$translations.support.message}&phone=${process.env.VUE_APP_SUPPORT_WHATSAPP}`
      if (this.$store.getters.platform !== 'web') {
        url = `whatsapp://send?text=${this.$translations.support.message}&phone=${process.env.VUE_APP_SUPPORT_WHATSAPP}`
      }
      await Browser.open({ url })
    },
    goPatientForm () {
      this.$router.push({
        name: 'PatientForm',
        params: {
          patientId: this.appointment.patient.id
        }
      })
    },
    retrieveInformedConsent () {
      this.sectionLoading = true
      SpeContracts.retrieveInformedConsent(this.appointment.patient.id)
        .then((response) => (this.informedConsent = response))
        .catch((error) => {
          this.$toast({
            severity: 'error',
            text: error.message
          })
        })
        .finally(() => {
          this.sectionLoading = false
        })
    },
    sendInformedConsent () {
      /* segment event */
      PatientDetail.consentSend({
        origin: true,
        patient_id: this.appointment.patient.id,
        specialist_id: this.appointment.specialist.id,
        status: this.informedConsent.status || 'CONTRACT_NOT_SENT'
      })

      this.buttonLoading = true
      SpeContracts.sendInformedConsent(this.appointment.patient.id)
        .then(() => {
          this.$toast({
            severity: 'success',
            text: this.$translations.success.default
          })
        })
        .catch((error) => {
          this.$toast({
            severity: 'error',
            text: error.message
          })
        })
        .finally(() => {
          this.buttonLoading = false
          this.retrieveInformedConsent()
        })
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      history: 'medical/history',
      isComplete: 'patient/isComplete'
    }),
    icon () {
      return ServiceIcons[this.appointment.service.service_type.icon_name]
    },
    virtual () {
      return this.appointment.virtual
        ? this.$translations.option.modality.online
        : this.$translations.option.modality['face-to-face']
    },
    cardFirstLine () {
      const gender = this.$translations.gender[this.appointment.patient.gender]
      return `${gender} • ${this.patientAge()} ${
        this.$translations.appointment.create.years
      }`
    },
    cardMiddleLine () {
      return this.appointment?.patient?.address?.country
        ? `${this.appointment?.patient?.address?.country}`
        : ''
    },
    showBanner () {
      const validStatus = ['NO_ATTEND_USER_WITH_RESCHEDULE']
      return validStatus.includes(this.appointment.status)
    },
    redStatus () {
      return (status) => {
        const allowedStatus = [
          'NO_ATTEND_USER',
          'NO_ATTEND_SPECIALIST',
          'NON_COMPLETED',
          'NO_ATTEND_USER_WITH_RESCHEDULE',
          'CANCELED',
          'EXPIRED'
        ]
        return allowedStatus.includes(status)
      }
    },
    showTermsAndConditions () {
      const status = ['COMPLETED', 'NO_ATTEND_USER', 'CANCELED']

      return status.includes(this.appointment.status)
    },
    showCallSupport () {
      const statusAllowed = [
        'IN_WAITING_ROOM',
        'IN_PROGRESS_WAITING',
        'IN_PROGRESS'
      ]

      if (this.isAboutToStart()) {
        statusAllowed.push('CONFIRM')
      }

      return statusAllowed.includes(this.appointment.status)
    },
    showRescheduleTerms () {
      const status = [
        'CONFIRM',
        'PENDING',
        'PROCESSING_PAYMENT',
        'PAYMENT_DECLINED',
        'NON_COMPLETED',
        'NO_ATTEND_SPECIALIST',
        'NO_ATTEND_USER_WITH_RESCHEDULE'
      ]

      if (this.showCallSupport) {
        return false
      }

      return status.includes(this.appointment.status)
    },
    clinicalHistory () {
      if (this.history.length > 1) {
        return false
      }

      if (this.history.length === 0) {
        return true
      }

      const draftEpisode = this.history.filter((i) => i.status === 'DRAFT')

      if (draftEpisode.length && this.history.length === 1) {
        return true
      }

      return false
    },
    showInformedConsentButton () {
      return this.informedConsent?.status === 'CONTRACT_NOT_SENT'
    }
  },
  filters: {
    priceAppointment,
    CurrencyFilter,
    labelAppointment
  }
}
</script>

<style lang="sass" scoped>
.service
  display: flex
  &-icon
    display: flex
    border-radius: var(--border-full)
    height: 48px
    width: 48px
    align-items: center
    margin-right: 16px
    text-align: center
    justify-content: center
  &-name
    align-self: center
    &-title
      margin-bottom: 4px
    &-subtitle
      color: var(--gray-60)
      &-danger
        color: var(--red)
  &-info
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 16px
    margin-top: 16px

.skeleton-container
  display: flex
  flex-direction: column
  &-grid
    display: grid
    grid-template-columns: repeat(2, 1fr)
    gap: 16px
    margin-top: 16px
    &-item
      width: 160px
      height: 48px
      border-radius: 16px
  &-buttons
    display: flex
    gap: 8px
    margin-top: 32px
    &-item
      width: 100%
      height: 48px
      border-radius: 8px
.hint
  margin-top: 32px
  text-align: center
  line-height: 15px
.informed-consent
  &-text
    text-align: center
.skeleton-informed-consent
  display: flex
  flex-direction: column
  gap: 16px
  &-button
    width: 100%
    height: 48px
    border-radius: 8px
  &-item
    height: 70px
    border-radius: 16px
</style>
